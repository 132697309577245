import "../../components/footerv2/newsletter-modal.scss";
import * as React from "react";
import classNames from "classnames";
import { AmazeeIOButton } from "../../components/button/button";
import Linked from "../../utils/linkedItem";
import Modal from "react-modal";
import { PageTitle } from "../../components/title/title";
import { navigate as gatsbyNavigate } from "gatsby";
import AmazeeIoMetadata from "../../components/metadata";
import HubspotForm from "../../components/hubspot-form";

const SubscribeToNewsLetterModal: React.FC<{
  isOpen: boolean;
  closeModal: Function;
  openModal: Function;
  location: Location;
}> = ({ isOpen, closeModal, location, openModal }) => {
  const customStyles = {
    overlay: {
      backgroundColor: "#183043",
    },
    content: {
      backgroundColor: "#183043",
      borderRadius: "0",
      border: "none",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      color: "white",
      textAlign: "center",
    },
  };

  const [buttonText, setButtonText] = React.useState("Subscribe");
  // const [firstName, setFirstName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  // const [firstNameError, setFirstNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);

  const [otherError, setOtherError] = React.useState(null);

  const resetErrors = () => {
    // setFirstNameError(false);
    setEmailError(false);
    setOtherError(null);
  };

  const resetForm = () => {
    // setFirstName("");
    // setLastName("");
    setEmail("");
  };

  // const updateFirstName = (e) => setFirstName(e.target.value);
  // const updateLastName = (e) => setLastName(e.target.value);
  const updateEmail = (e) => setEmail(e.target.value);

  React.useEffect(() => {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1); // Puts hash in variable, and removes the # character
      if (hash == "newsletter-subscribe") {
        setTimeout(openModal, 100);
      }
    }
  }, []);

  const startSubmit = () => {
    resetErrors();
    let foundError = false;

    // if (!firstName || firstName == "") {
    //   setFirstNameError(true);
    //   foundError = true;
    // }

    if (!email || email == "") {
      setEmailError(true);
      foundError = true;
    }

    if (!foundError) {
      setButtonText("...");

      // Looks good, lets submit
      fetch("/api/subscribe/", {
        method: "POST",
        body: JSON.stringify({
          // firstName,
          // lastName,
          email,
          page: location ? location.href : null,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        // .then(d => d.json())
        .then((val) => {
          if (val.error) {
            if (
              val.details &&
              val.details.errors &&
              val.details.errors.length &&
              val.details.errors[0].message
            ) {
              setOtherError(val.details.errors[0].message);
            } else {
              setOtherError("An error occured");
            }
          } else {
            resetForm();
            setButtonText("Subscribed!");
            // pixelDrop('newsletter');

            setTimeout(closeModal, 1500);
          }
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      style={customStyles}
      className="subscribe-to-newsletter-modal"
      overlayClassName="subscribe-to-newsletter-overlay"
      onRequestClose={closeModal}
    >
      <div className="content">
        <h1>Subscribe to our Newsletter</h1>
        <div className="close-button" onClick={closeModal}>
          X
        </div>
        <p className="text-white text-left my-4">
          Stay up to date with the latest news about our product, services,
          events and all things amazee.io.
        </p>
        <HubspotForm
          id="footerv2-49c326ec0e78"
          formId="803424ab-8bf1-49d3-bf6f-49c326ec0e78"
          portalId="5096486"
        />

        {/* {otherError && <div className="other-error">{otherError}</div>}
        <AmazeeIOButton
          classes="inverted mt-3 inline-block px-4 py-1 cursor-pointer"
          onClick={startSubmit}
        >
          {buttonText}
        </AmazeeIOButton> */}

        {/* <div className="text-xs text-left mt-3">
          We will use the personal data you are sharing with us solely for the
          purpose of sending you our newsletter. See our{" "}
          <Linked link_to={{ document: { data: { url: "/privacy-policy" } } }}>
            Privacy Policy
          </Linked>
          .
        </div> */}
      </div>
    </Modal>
  );
};

export default ({ location }) => {
  return (
    <div id="newsletter-signup">
      <PageTitle title="Newsletter Signup | amazee.io" />
      <AmazeeIoMetadata title="Newsletter signup!" location={location} />

      <div className="content">
        <SubscribeToNewsLetterModal
          location={location}
          isOpen={true}
          closeModal={() => gatsbyNavigate("/", {})}
          openModal={() => {}}
        />
      </div>
    </div>
  );
};
